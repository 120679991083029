<template>
  <v-container class="form">
    <v-form v-model="validationForm" ref="validation" lazy-validation>
      <v-row>
        <v-col xs="12" lg="4">
          <h1 class="secondary--text">Cadastrar Novo Coletor</h1>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col lg="2">
          <label class="label">Nome</label>
        </v-col>
        <v-col xs="12" lg="4">
          <v-text-field
            v-model.trim="collector.name"
            label="Nome"
            maxlength="70"
            :rules="[rule.required]"
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="collectorType === 'client'" class="mt-8">
        <v-col lg="2">
          <label class="label">Grupo Financeiro</label>
        </v-col>
        <v-col xs="12" lg="4">
          <v-autocomplete
            v-model="collector.financialGroupId"
            :items="financialGroups"
            :rules="collectorType === 'client' ? [rule.required] : []"
            item-text="name"
            item-value="id"
            :loading="loadingFinancialGroup"
            :disabled="isDisable"
            placeholder="Selecione um Grupo Financeiro"
            solo
            color="textPrimary"
            @input="getContractsByFinancialGroupId(collector.financialGroupId)"
          />
        </v-col>
      </v-row>
      <v-row v-if="collectorType === 'client'">
        <v-col lg="2">
          <label class="label">Contrato</label>
        </v-col>
        <v-col lg="5">
          <v-autocomplete
            v-model="collector.contractId"
            :items="contracts"
            item-text="text"
            item-value="id"
            clearable
            :loading="loadingContracts"
            placeholder="Selecione um Contrato"
            :disabled="!collector.financialGroupId"
            solo
            color="textPrimary"
            @input="getSubContractsByContractId(collector.contractId)"
          />
        </v-col>
      </v-row>
      <v-row v-if="collectorType === 'client'">
        <v-col lg="2">
          <label class="label">Sub-Contrato</label>
        </v-col>
        <v-col lg="5">
          <v-autocomplete
            v-model="collector.subContractId"
            :items="subContracts"
            item-text="text"
            item-value="id"
            clearable
            :loading="loadingSubContracts"
            placeholder="Selecione um Subcontrato"
            :disabled="!collector.contractId || loadingSubContracts"
            solo
            color="textPrimary"
          />
        </v-col>
      </v-row>
      <v-row v-if="collectorType === 'carrier'" class="mt-8">
        <v-col lg="2">
          <label class="label">Operadora</label>
        </v-col>
        <v-col lg="5">
          <v-autocomplete
            v-model="collector.carrierId"
            :items="insuranceCarriers"
            :disabled="this.loadingInsuranceCarriers"
            :rules="collectorType === 'carrier' ? [rule.required] : []"
            item-text="xipp_commercial_name"
            item-value="id"
            :loading="loadingInsuranceCarriers"
            placeholder="Selecione uma Operadora"
            solo
            color="textPrimary"
          />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col lg="2">
          <label class="label">Tipo do Integração</label>
        </v-col>
        <v-col lg="7">
          <v-btn-toggle v-model="collector.types" multiple dense mandatory>
            <div style="background-color: var(--v-background-base)">
              <v-btn
                value="FTP"
                small
                active-class="primary"
                min-width="92"
                class="mr-6"
              >
                FTP
              </v-btn>
              <v-btn
                value="EMAIL"
                small
                active-class="primary"
                min-width="92"
                class="mr-6"
              >
                E-mail
              </v-btn>
              <v-btn
                value="PAYROLL"
                small
                active-class="primary"
                min-width="92"
                class="mr-6"
              >
                Folha
              </v-btn>
              <v-btn
                value="API"
                small
                active-class="primary"
                min-width="92"
                class="mr-6"
              >
                API
              </v-btn>
            </div>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row class="justify-end mt-10">
        <v-col cols="8">
          <v-btn x-large outlined color="primary" @click="onClickCancel()">
            Cancelar
          </v-btn>
          <v-btn class="ml-5" x-large color="primary" @click="onClickSave()">
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Rules from '@/shared/validators/formRules';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CollectorIntegration',
  data() {
    return {
      validationForm: false,
      isDisable: false,
      collector: {
        name: '',
        financialGroupId: '',
        carrierId: '',
        contractId: '',
        subContractId: '',
        types: ['FTP'],
      },

      financialGroups: [],
      contracts: [],
      subContracts: [],
      insuranceCarriers: [],

      loadingFinancialGroup: false,
      loadingContracts: false,
      loadingSubContracts: false,
      loadingInsuranceCarriers: false,

      financialGroupId: '',

      collectorType: '',
    };
  },

  mixins: [
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
    ContractsMixin,
  ],

  mounted() {
    this.loadCollectorType();
    if (this.$route.query.financialGroupId) {
      this.financialGroupId = Number(this.$route.query.financialGroupId);
      this.collector.financialGroupId = this.financialGroupId;
      this.getContractsByFinancialGroupId(this.collector.financialGroupId);
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.collectorType === 'client') {
      this.getFinancialGroups();
    } else if (this.collectorType === 'carrier') {
      this.getInsuranceCarriers();
    }
  },

  methods: {
    ...mapMutations({
      setCollector: 'collectors/setCollector',
      setEditCollector: 'collectors/setEditCollector',
      setCollectorParams: 'collectors/setCollectorParams',
      setCollectorsConfigs: 'collectors/setCollectorsConfigs',
    }),
    loadCollectorType() {
      // this.collectorType = sessionStorage.getItem('collector');
      this.collectorType = JSON.parse(JSON.stringify(this.collectorData));
    },
    onClickCancel() {
      // sessionStorage.removeItem('collector');
      sessionStorage.removeItem('describe-contract');
      this.$router.push('/collectors');
    },
    onClickSave() {
      if (this.$refs.validation.validate()) {
        if (this.collectorType === 'client') {
          this.payloadIsFinancialGroup();
        } else if (this.collectorType === 'carrier') {
          this.payloadIfInsuranceCarrier();
        }
        this.$router.push({ name: 'CollectorIntegration' });
      }
    },
    payloadIsFinancialGroup() {
      let payload = {};
      const financialGroup = this.financialGroups.find((item) => item.id === this.collector.financialGroupId);
      const contract = this.contracts.find((item) => item.id === this.collector.contractId);
      const subContract = this.subContracts.find((item) => item.id === this.collector.subContractId);
      const contractPayload = {
        benefit: contract && contract.benefit_id ? contract.benefit_id : null,
        carrierName: contract && contract.carrier_id && contract.carrier_id.name ? contract.carrier_id.name : null,
        id: contract && contract.id ? contract.id : null,
        isSubContract: !!(contract && contract.subcontract),
        legalName: contract && contract.carrier_id && contract.carrier_id.legal_name ? contract.carrier_id.legal_name : null,
        policy: contract && contract.policy ? contract.policy : null,
        subContractNumber: contract && contract.subcontract_number ? contract.subcontract_number : null,
      };
      const subContractPayload = {
        benefit: subContract && subContract.benefit_id ? subContract.benefit_id : null,
        carrierName: subContract && subContract.carrier_id && subContract.carrier_id.name ? subContract.carrier_id.name : null,
        id: subContract && subContract.id ? subContract.id : null,
        isSubContract: !!(subContract && subContract.subcontract),
        legalName: subContract && subContract.carrier_id && subContract.carrier_id.legal_name ? subContract.carrier_id.legal_name : null,
        policy: subContract && subContract.policy ? subContract.policy : null,
        subContractNumber: subContract && subContract.subcontract_number ? subContract.subcontract_number : null,
      };

      payload = {
        collectorType: this.collectorType,
        collectorName: this.collector.name,
        collector: {
          entity: 'FINANCIAL_GROUP',
          types: this.collector.types,
          financialGroup: {
            id: financialGroup.id,
            name: financialGroup.name,
          },
          contract: contractPayload,
          subContract: subContractPayload,
        },
      };

      // sessionStorage.setItem('collector', JSON.stringify(payload));
      this.setCollector(payload);
    },
    payloadIfInsuranceCarrier() {
      let payload = {};
      const insuranceCarrier = this.insuranceCarriers.filter((item) => item.id === this.collector.carrierId);
      payload = {
        collectorName: this.collector.name,
        collectorType: this.collectorType,
        collector: {
          entity: 'INSURANCE_CARRIER',
          insuranceCarrier: {
            id: insuranceCarrier[0].id,
            name: insuranceCarrier[0].name,
          },
          types: this.collector.types,
        },
      };
      // sessionStorage.setItem('collector', JSON.stringify(payload));
      this.setCollector(payload);
    },
  },

  computed: {
    ...mapGetters({
      collectorData: 'collectors/collector',
      editCollectorData: 'collectors/editCollector',
      collectorParamsData: 'collectors/collectorParams',
      collectorsConfigsData: 'collectors/collectorsConfigs',
    }),
  },

  async created() {
    this.rule = new Rules();
  },
};
</script>
